import React, {useMemo, useState} from 'react';
import { Link } from 'react-router-dom';
import {Badge, Col, Collapse, Input, Layout, Menu, Modal, Row, Tooltip} from 'antd';
import type { CollapseProps } from 'antd';
import {
    AlertOutlined, InboxOutlined, SendOutlined,
} from '@ant-design/icons';

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const itemsNest: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Serwery nie działają - 23.04.2024',
        children: <p>{text}</p>,
    },
];

const items: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Od: biuro@2strefa.pl',
        children: <div><Collapse className={'pader'} defaultActiveKey="1" items={itemsNest} /><Collapse className={'pader'} defaultActiveKey="1" items={itemsNest} /><Collapse className={'pader'} defaultActiveKey="1" items={itemsNest} /><Input.TextArea rows={4} className={'pader'} placeholder="Napisz wiadomość do klienta" /></div>,
    },
    {
        key: '2',
        label: 'This is panel header 2',
        children: <p>{text} <Input.TextArea rows={4} className={'pader'} placeholder="Napisz wiadomość do klienta" /></p>,
    },
    {
        key: '3',
        label: 'This is panel header 3',
        children: <p>{text} <Input.TextArea rows={4} className={'pader'} placeholder="Napisz wiadomość do klienta" /></p>,
    },
];
const HeaderListwa: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setModalText('Wysyłam wiadomości...');
        setConfirmLoading(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    return (
        <Col>
            <ul className={'infolistwa'}>
                <li>
                    <Tooltip placement="bottom" title={'Skrzynka odbiorcza'} arrow={mergedArrow}><InboxOutlined className={'fonter'}/></Tooltip>
                </li>
                <li>
                    <Badge count={1}>
                        <Tooltip placement="bottom" title={'Wszystkie Wiadomości'} arrow={mergedArrow}><SendOutlined className={'fonter'} onClick={showModal} /></Tooltip>
                    </Badge>
                </li>
                <li>
                    <Tooltip placement="bottom" title={'Alerty ogólne'} arrow={mergedArrow}><AlertOutlined className={'fonter'} onClick={showModal2} /></Tooltip>
                </li>
            </ul>
            <Modal
                title="Wszystkie Wiadomości"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={<span>Wyślij <SendOutlined /></span>}
                width={900}
                cancelText={<span>Anuluj</span>}
            >
                <Collapse items={items} defaultActiveKey={['1']} onChange={onChange} />
            </Modal>
            <Modal title="Alerty ogólne" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal>
        </Col>
    );
}

export default HeaderListwa;