import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Col, Layout, Menu} from 'antd';
import type { MenuProps } from 'antd';
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    LogoutOutlined,
    DashboardOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    url?: string,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon: url ? <Link to={url}>{icon}</Link> : icon,
        children,
        label: url ? <Link to={url}>{label}</Link> : label,
    } as MenuItem;
}
const items: MenuItem[] = [
    getItem('Panel admin', '1', <DashboardOutlined />,'/adminpanel', ),
    getItem('Klienci', '2', <UserOutlined />, '/klienci'),
    getItem('User', 'sub1', <UserOutlined />, '',[
        getItem('Tom', '3', undefined, '/user/tom'),
        getItem('Bill', '4', undefined, '/user/tom'),
        getItem('Alex', '5', undefined,  '/user/tom'),
    ]),
    getItem('Team', 'sub2', <TeamOutlined />, '',[
        getItem('Team 1', '6',undefined,  '/team1'),
        getItem('Team 2', '8', undefined, '/team1')
    ]),
    getItem('Files', '9', <FileOutlined />, '/adminpanel'),
    getItem('Wyloguj', '10', <LogoutOutlined />, ''),
];

interface MenuSiderProps {
    defaultKey: string;
}

const MenuSider: React.FC<MenuSiderProps> = ({ defaultKey }) => {
    const [collapsed, setCollapsed] = useState(true);
    const onMenuClick = (event: any) => {
        if (event.key === '10') {
            // Logout is triggered here
            const token = localStorage.getItem('token');
            if (token) {
                localStorage.removeItem('token');
            }
            window.location.href = '/';
        }
    };
    return (
        <Sider className={'listwaa'} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Col className={'logos'}><Link to={'/'}><img src="/images/logosyyy.png" alt="Logo" width={50} /></Link></Col>
            <Menu theme="light" defaultSelectedKeys={[defaultKey]} mode="inline" items={items} onClick={onMenuClick} />
        </Sider>
    );
}

export default MenuSider;