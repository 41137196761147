import React, {useEffect, useMemo, useState} from 'react';
import { Helmet, HelmetProvider} from "react-helmet-async";
import {Link, useNavigate} from 'react-router-dom';
import useAuth from "../components/useAuth";
import {
    Breadcrumb,
    Button,
    Col,
    ConfigProvider,
    Drawer,
    Flex,
    Form,
    Input,
    Layout,
    Row,
    Select,
    Checkbox,
    DatePicker,
    theme,
    message,
    Tooltip, Divider, Badge, Alert, Popconfirm, PopconfirmProps, type FormProps
} from 'antd';
import type { GetProp } from 'antd';
import MenuSider from "../components/MenuSider";
import {
    AlertOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DashboardOutlined, DeleteOutlined,
    EditOutlined, InfoCircleOutlined, PlusOutlined, SendOutlined, StopOutlined, UserOutlined,
    WarningOutlined
} from "@ant-design/icons";
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import HeaderListwa from "../components/HeaderListwa";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import axios from "axios";

interface CustomButtonProps {
    onDelete: (id: number) => void;
    userId: number;
}
export interface UserData {
    id: number;
    email: string;
    terms_accepted: boolean;
    panel_activate: boolean;
    potwierdzenie_platnosci: boolean;
    dane_klienta: boolean;
    newsletter: boolean;
    kim_jestem: boolean;
    imie: string;
    nazwisko: string;
    telefon: string;
    telefon_dodatkowy?: string;
    ulica: string;
    numerdomu: string;
    nrmieszkanie?: string;
    miasto: string;
    kodpocztowy: string;
    nazwafirmy?: string;
    nip?: string;
    regon?: string;
    uwagi?: string;
    notification_potwierdzenie_platnosci: boolean;
    notification_dane_klienta: boolean;
    notification_panel_activate: boolean;
}
type FieldType = {
    id: number;
    email: string;
    terms_accepted: boolean;
    panel_activate: boolean;
    potwierdzenie_platnosci: boolean;
    dane_klienta: boolean;
    newsletter: boolean;
    kim_jestem: boolean;
    imie: string;
    nazwisko: string;
    telefon: string;
    telefon_dodatkowy?: string;
    ulica: string;
    numerdomu: string;
    nrmieszkanie?: string;
    miasto: string;
    kodpocztowy: string;
    nazwafirmy?: string;
    nip?: string;
    regon?: string;
    uwagi?: string;
    notification_potwierdzenie_platnosci: boolean;
    notification_dane_klienta: boolean;
    notification_panel_activate: boolean;
};

const { Option } = Select;

const KlienciPanel: React.FC = () => {
    useAuth();
    const navigate = useNavigate();
    const [userData, setUserData] = useState<UserData[]>([]);
    useEffect(() => {
        getJSON();
    }, []);

    async function getJSON() {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(apiUrl+'/api/dane-userow-wszystkich/', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Token '+ token,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const userData = await response.json() as UserData[];
            setUserData(userData);
        } catch (error: any) {
            console.log(error.message || 'An error occurred');
        }
    };

    const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [open, setOpen] = useState(false);
    const showDrawer = (user: UserData) => {
        setTermsAccepted(user.terms_accepted);
        setPanelActivate(user.panel_activate);
        setPotwierdzeniePlatnosci(user.potwierdzenie_platnosci);
        setDaneKlienta(user.dane_klienta);
        setNewsletter(user.newsletter);
        setFlagaplatnosc(user.notification_potwierdzenie_platnosci);
        setFlagaDaneklienta(user.notification_dane_klienta);
        setFlagaPanelactive(user.notification_panel_activate);
        setSelectedUser(user);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setSelectedUser(null);
    };

    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const columns: TableProps<UserData>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Kim jestem',
            dataIndex: 'kim_jestem',
            key: 'kim_jestem',
            render: (kim_jestem) => kim_jestem ? 'Firma' : 'Osoba prywatna'
        },
        {
            title: 'Imię',
            dataIndex: 'imie',
            key: 'imie',
            render: (imie) => imie ? imie : '-'
        },
        {
            title: 'Nazwisko',
            dataIndex: 'nazwisko',
            key: 'nazwisko',
            render: (nazwisko) => nazwisko ? nazwisko : '-'
        },
        {
            title: 'Telefon',
            dataIndex: 'telefon',
            key: 'telefon',
            render: (telefon) => telefon ? telefon : '-'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <>
                        <Tooltip placement="bottom" title={'Edytuj'} arrow={mergedArrow}>
                            <EditOutlined onClick={() => showDrawer(record)} />
                        </Tooltip>
                    </>
                    <>
                        <CustomButton userId={record.id} onDelete={DeleteClient}/>
                    </>
                </Space>
            ),
        },
    ];

    const [email, setEmail] = useState<string>();
    const [kim_jestem, setKim_jestem] = useState<boolean>();
    const [imie, setImie] = useState<string>();
    const [nazwisko, setNazwisko] = useState<string>();
    const [ulica, setUlica] = useState<string>();
    const [numerdomu, setNumerdomu] = useState<string>();
    const [nrmieszkanie, setNrmieszkanie] = useState<string>();
    const [kodpocztowy, setKodpocztowy] = useState<string>();
    const [miasto, setMiasto] = useState<string>();
    const [telefon, setTelefon] = useState<string>();
    const [telefon_dodatkowy, setTelefon_dodatkowy] = useState<string>();
    const [nazwafirmy, setNazwafirmy] = useState<string>();
    const [nip, setNip] = useState<string>();
    const [regon, setRegon] = useState<string>();
    const [uwagi, setUwagi] = useState<string>();
    const [termsAccepted, setTermsAccepted] = useState<boolean>();
    const [panelActivate, setPanelActivate] = useState<boolean>();
    const [potwierdzeniePlatnosci, setPotwierdzeniePlatnosci] = useState<boolean>();
    const [daneKlienta, setDaneKlienta] = useState<boolean>();
    const [newsletter, setNewsletter] = useState<boolean>();
    const [flagaplatnosc, setFlagaplatnosc] = useState<boolean>();
    const [flagadaneklienta, setFlagaDaneklienta] = useState<boolean>();
    const [flagapanelactive, setFlagaPanelactive] = useState<boolean>();

    const onEdit = async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl =  process.env.REACT_APP_API_URL;
            const response = await fetch(apiUrl+'/api/edytuj-usera/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                },
                body: JSON.stringify({
                    id: selectedUser?.id,
                    email,
                    kim_jestem,
                    imie,
                    nazwisko,
                    ulica,
                    numerdomu,
                    nrmieszkanie,
                    kodpocztowy,
                    miasto,
                    telefon,
                    telefon_dodatkowy,
                    nazwafirmy,
                    nip,
                    regon,
                    uwagi,
                    terms_accepted: termsAccepted,
                    panel_activate: panelActivate,
                    potwierdzenie_platnosci: potwierdzeniePlatnosci,
                    dane_klienta: daneKlienta,
                    newsletter: newsletter,
                    notification_potwierdzenie_platnosci: flagaplatnosc,
                    notification_dane_klienta: flagadaneklienta,
                    notification_panel_activate: flagapanelactive
                }),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                const updatedUserData = userData.map(user =>
                    user.id === selectedUser?.id
                        ? {
                            ...user,
                            email: email ?? user.email,
                            kim_jestem: kim_jestem ?? user.kim_jestem,
                            imie: imie ?? user.imie,
                            nazwisko: nazwisko ?? user.nazwisko,
                            ulica: ulica ?? user.ulica,
                            numerdomu: numerdomu ?? user.numerdomu,
                            nrmieszkanie: nrmieszkanie ?? user.nrmieszkanie,
                            kodpocztowy: kodpocztowy ?? user.kodpocztowy,
                            miasto: miasto ?? user.miasto,
                            telefon: telefon ?? user.telefon,
                            telefon_dodatkowy: telefon_dodatkowy ?? user.telefon_dodatkowy,
                            nazwafirmy: nazwafirmy ?? user.nazwafirmy,
                            nip: nip ?? user.nip,
                            regon: regon ?? user.regon,
                            uwagi: uwagi ?? user.uwagi,
                            terms_accepted: termsAccepted ?? user.terms_accepted,
                            panel_activate: panelActivate ?? user.panel_activate,
                            potwierdzenie_platnosci: potwierdzeniePlatnosci ?? user.potwierdzenie_platnosci,
                            dane_klienta: daneKlienta ?? user.dane_klienta,
                            newsletter: newsletter ?? user.newsletter,
                            notification_potwierdzenie_platnosci: flagaplatnosc ?? user.notification_potwierdzenie_platnosci,
                            notification_dane_klienta: flagadaneklienta ?? user.notification_dane_klienta,
                            notification_panel_activate: flagapanelactive ?? user.notification_panel_activate
                        }
                        : user
                );
                setUserData(updatedUserData);
                setOpen(false);
                setSelectedUser(null);
            } else {
                alert('Dane nie zostały zapisane!');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                alert(error.response.data.detail);
            } else {
                // @ts-ignore
                alert(error.message);
            }
        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const DeleteClient = async (userId: number) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl =  process.env.REACT_APP_API_URL;
            const response = await fetch(apiUrl+'/api/usuwanie-klienta-z-bazy/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+ token,
                },
                body: JSON.stringify({
                    id: userId,
                }),
            });
            if (response.ok) {
                const updatedUserData = userData.filter((user) => user.id !== userId);
                setUserData(updatedUserData);
                message.success('Klient usunięty!');
            } else {
                alert('Dane nie zostały zapisane!');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                alert(error.response.data.detail);
            } else {
                // @ts-ignore
                alert(error.message);
            }
        }
    };

    const CustomButton = React.forwardRef<HTMLDivElement, CustomButtonProps>((props, ref) => (
        <Tooltip placement="bottom" title={'Usuń'}>
            <div ref={ref}>
                <Popconfirm
                    title="Usuń klienta"
                    description="Czy napewno chcesz usunąć tego klienta?"
                    onConfirm={() => props.onDelete(props.userId)}
                    onCancel={cancel}
                    okText="Tak"
                    cancelText="Nie"

                >
                    <DeleteOutlined />
                </Popconfirm>
            </div>
        </Tooltip>
    ));

    const { Header, Footer, Content } = Layout;
    const confirm: PopconfirmProps['onConfirm'] = (e) => {
        console.log(e);
        message.success('Klient usunięty!');
    };

    const cancel: PopconfirmProps['onCancel'] = (e) => {
        console.log(e);
        message.error('Anulowano');
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Klienci | 2SAdmin</title>
                </Helmet>
            </HelmetProvider>
            <Layout style={{ minHeight: '100vh' }}>
                <MenuSider defaultKey="2" />
                <Layout>
                    <Header style={{ paddingLeft: 20, background: 'white' }}>
                        <Row>
                            <Col span={12}><h2><UserOutlined /> Klienci</h2></Col>
                            <Col span={12}><Flex style={{paddingBottom: 20}} align="flex-end" justify="right"><HeaderListwa/></Flex></Col>
                        </Row>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}
                                    items={[
                                        {
                                            title: 'Główna',
                                        },
                                        {
                                            title: 'Klienci',
                                        },
                                    ]}
                        />
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <div>
                                {userData.length > 0 ? (
                                    <Table columns={columns} dataSource={userData} rowKey="id"/>
                                ) : (
                                    <p>Brak danych</p>
                                )}
                            </div>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        2SAdmin ©{new Date().getFullYear()} Created by LoveApp.pl
                    </Footer>
                </Layout>
            </Layout>
            <Drawer
                title="Dane klienta"
                width={720}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Anuluję</Button>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#00b96b',
                                    borderRadius: 5,
                                    colorBgContainer: '#f6ffed',
                                },
                            }}
                        >
                            <Button onClick={onEdit} type="primary">
                                Akceptuję
                            </Button>
                        </ConfigProvider>
                    </Space>
                }
            >
                {selectedUser &&
                    <Form
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onEdit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="E-mail:"
                                rules={[{ required: true, message: 'Pole E-mail jest wymagane!' }]}
                                initialValue={selectedUser.email}
                            >
                                <Input placeholder="Wpisz E-mail" onChange={e => setEmail(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="kim_jestem"
                                label="Rodzaj:"
                                initialValue={selectedUser ? (selectedUser.kim_jestem ? 'firma' : 'osoba') : 'osoba'}
                            >
                                <Select
                                    value={selectedUser ? (selectedUser.kim_jestem ? 'firma' : 'osoba') : 'osoba'}
                                    onChange={(value) => setKim_jestem(value === 'firma')}
                                >
                                    <Option value="firma">Firma</Option>
                                    <Option value="osoba">Osoba prywatna</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="imie"
                                label="Imię:"
                                rules={[{ required: true, message: 'Pole Imię jest wymagane!' }]}
                                initialValue={selectedUser.imie}
                            >
                                <Input placeholder="Wpisz Imię" onChange={e => setImie(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="nazwisko"
                                label="Nazwisko:"
                                rules={[{ required: true, message: 'Pole Nazwisko jest wymagane!' }]}
                                initialValue={selectedUser.nazwisko}
                            >
                                <Input placeholder="Wpisz Nazwisko" onChange={e => setNazwisko(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="ulica"
                                label="Ulica:"
                                initialValue={selectedUser.ulica}
                                rules={[{ required: true, message: 'Pole Ulica jest wymagane!' }]}
                            >
                                <Input placeholder="Wpisz ulicę" onChange={e => setUlica(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="numerdomu"
                                label="Nr. domu:"
                                initialValue={selectedUser.numerdomu}
                            >
                                <Input placeholder="Wpisz Nr. domu" onChange={e => setNumerdomu(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="nrmieszkanie"
                                label="Nr. mieszkania:"
                                initialValue={selectedUser.nrmieszkanie}
                            >
                                <Input placeholder="Wpisz Nr. mieszkania" onChange={e => setNrmieszkanie(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                name="kodpocztowy"
                                label="Kod pocztowy:"
                                initialValue={selectedUser.kodpocztowy}
                                rules={[{ required: true, message: 'Pole Kod pocztowy jest wymagane!' }]}
                            >
                                <Input placeholder="Wpisz Kod pocztowy" onChange={e => setKodpocztowy(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="miasto"
                                label="Miasto:"
                                rules={[{ required: true, message: 'Pole Miasto jest wymagane!' }]}
                                initialValue={selectedUser.miasto}
                            >
                                <Input placeholder="Wpisz Miasto" onChange={e => setMiasto(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="telefon"
                                label="Telefon:"
                                rules={[{ required: true, message: 'Pole Telefon jest wymagane!' }]}
                                initialValue={selectedUser.telefon}
                            >
                                <Input placeholder="Wpisz Telefon" onChange={e => setTelefon(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="telefon_dodatkowy"
                                label="Telefon dodatkowy:"
                                rules={[{ required: true, message: 'Pole Telefon dodatkowy jest wymagane!' }]}
                                initialValue={selectedUser.telefon_dodatkowy}
                            >
                                <Input placeholder="Wpisz Telefon dodatkowy" onChange={e => setTelefon_dodatkowy(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="nazwafirmy"
                                label="Nazwa firmy:"
                                initialValue={selectedUser.nazwafirmy}
                            >
                                <Input placeholder="Wpisz pełną nazwę firmy" onChange={e => setNazwafirmy(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="nip"
                                label="NIP:"
                                initialValue={selectedUser.nip}
                            >
                                <Input placeholder="Wpisz NIP bez myślników" onChange={e => setNip(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="regon"
                                label="REGON:"
                                initialValue={selectedUser.regon}
                            >
                                <Input placeholder="Wpisz REGON bez spacji" onChange={e => setRegon(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Zgody:">
                                <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}>Akceptuję regulamin</Checkbox>
                                <Checkbox checked={potwierdzeniePlatnosci} onChange={e => setPotwierdzeniePlatnosci(e.target.checked)}>Potwierdzenie płatności</Checkbox>
                                <Checkbox checked={daneKlienta} onChange={e => setDaneKlienta(e.target.checked)}>Dane klienta</Checkbox>
                                <Checkbox checked={panelActivate} onChange={e => setPanelActivate(e.target.checked)}>Aktywacja panelu</Checkbox>
                                <Checkbox checked={newsletter} onChange={e => setNewsletter(e.target.checked)}>Newsletter</Checkbox>
                            </Form.Item>
                            <Form.Item
                                label="Jednorazowa wysyłka(zmiany są automatyczne):"
                                tooltip={{ title: 'Gdy zaznaczony jest kwadrat, dalsze maile nie będą wysyłane przy kolejnej edycji danych.', icon: <InfoCircleOutlined /> }}
                            >
                                <Checkbox checked={flagaplatnosc} onChange={e => setFlagaplatnosc(e.target.checked)}>Wysłano Maila o płatności</Checkbox>
                                {/*<Checkbox checked={flagadaneklienta} onChange={e => setFlagaDaneklienta(e.target.checked)}>Wysłano SMS Dane</Checkbox>*/}
                                {/*<Checkbox checked={flagapanelactive} onChange={e => setFlagaPanelactive(e.target.checked)}>Wysłano SMS i Maila o Aktywacji Panelu</Checkbox>*/}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="uwagi"
                                label="Uwagi prywatne:"
                                initialValue={selectedUser.uwagi}
                            >
                                <Input.TextArea rows={4} placeholder="Treść widoczna jest tylko do Twojej wiadomości" onChange={e => setUwagi(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> }
            </Drawer>
        </div>
    );
}

export default KlienciPanel;