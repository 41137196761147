import React, {useMemo, useState} from 'react';
import { Helmet, HelmetProvider} from "react-helmet-async";
import { Link } from 'react-router-dom';
import useAuth from "../components/useAuth";
import {
    Breadcrumb,
    Button,
    Col,
    ConfigProvider,
    Drawer,
    Flex,
    Form,
    Input,
    Layout,
    Row,
    Select,
    DatePicker,
    theme,
    message,
    Tooltip, Divider, Badge, Alert, Popconfirm, PopconfirmProps
} from 'antd';
import MenuSider from "../components/MenuSider";
import {
    AlertOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DashboardOutlined, DeleteOutlined,
    EditOutlined, InfoCircleOutlined, PlusOutlined, SendOutlined, StopOutlined,
    WarningOutlined
} from "@ant-design/icons";
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import HeaderListwa from "../components/HeaderListwa";

interface CustomButtonProps {
    onClick?: () => void;
}

const CustomButton = React.forwardRef<HTMLDivElement, CustomButtonProps>((props, ref) => (
        <Tooltip placement="bottom" title={'Usuń'}>
            <div ref={ref}>
                <Popconfirm
                    title="Usuń klienta"
                    description="Czy napewno chcesz usunąć tego klienta?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Tak"
                    cancelText="Nie"

                >
                    <DeleteOutlined onClick={props.onClick} />
                </Popconfirm>
            </div>
        </Tooltip>
));

const { Header, Footer, Content } = Layout;
const confirm: PopconfirmProps['onConfirm'] = (e) => {
    console.log(e);
    message.success('Klient usunięty!');
};

const cancel: PopconfirmProps['onCancel'] = (e) => {
    console.log(e);
    message.error('Anulowano');
};
interface DataType {
    id: number;
    key: string;
    name: string;
    age: string;
    address: string;
    status: string;
}

const data: DataType[] = [
    {
        id: 1,
        key: '1',
        name: 'dobryserwisagd@gmail.com',
        age: 'Plan Podstawowy',
        address: '€ 23.00 / miesiąc',
        status: 'success',
    },
    {
        id: 2,
        key: '2',
        name: 'czytarudaonomatopeja@gmail.com',
        age: 'Plan Professional',
        address: '€ 1,990.00 / rok',
        status: 'error',
    },
    {
        id: 3,
        key: '3',
        name: 'biuro@2strefa.pl',
        age: '-',
        address: '-',
        status: 'warning',
    },
];

const { Option } = Select;

const AdminPanel: React.FC = () => {
    useAuth();
    const [show, setShow] = useState(true);
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const [open2, setOpen2] = useState(false);
    const showDrawer2 = () => {
        setOpen2(true);
    };

    const onClose2 = () => {
        setOpen2(false);
    };

    const [open3, setOpen3] = useState(false);
    const showDrawer3 = () => {
        setOpen3(true);
    };

    const onClose3 = () => {
        setOpen3(false);
    };

    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Email klienta',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Wykupiony Plan',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Cena / Okres',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => {
                let icon;
                switch (record.status) {
                    case 'success':
                        icon = <Tooltip placement="bottom" title={'Opłacony'} arrow={mergedArrow}><CheckCircleOutlined style={{ color: 'green' }} /></Tooltip>;
                        break;
                    case 'error':
                        icon = <Tooltip placement="bottom" title={'Nieopłacony'} arrow={mergedArrow}><CloseCircleOutlined style={{ color: 'red' }} /></Tooltip>;
                        break;
                    case 'warning':
                        icon = <Tooltip placement="bottom" title={'Nieprzypisany'} arrow={mergedArrow}><WarningOutlined style={{ color: 'orange' }} /></Tooltip>;
                        break;
                    default:
                        icon = null;
                }
                return icon;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <>
                        <Tooltip placement="bottom" title={'Edytuj'} arrow={mergedArrow}>
                            <EditOutlined  onClick={showDrawer} />
                        </Tooltip>
                    </>
                    <>
                        <Tooltip placement="bottom" title={'Wiadomość'} arrow={mergedArrow}>
                            <Badge dot={show}>
                                <SendOutlined onClick={showDrawer2} />
                            </Badge>
                        </Tooltip>
                    </>
                    <>
                        <Tooltip placement="bottom" title={'Alert'} arrow={mergedArrow}>
                            <Badge dot={show}>
                                <AlertOutlined onClick={showDrawer3} />
                            </Badge>
                        </Tooltip>
                    </>
                    <>
                        <CustomButton />
                    </>
                </Space>
            ),
        },
    ];
    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Panel administracyjny | 2SAdmin</title>
                </Helmet>
            </HelmetProvider>
            <Layout style={{ minHeight: '100vh' }}>
                <MenuSider defaultKey="1" />
                <Layout>
                    <Header style={{ paddingLeft: 20, background: 'white' }}>
                        <Row>
                            <Col span={12}><h2><DashboardOutlined/> Panel Administratora</h2></Col>
                            <Col span={12}><Flex style={{paddingBottom: 20}} align="flex-end" justify="right"><HeaderListwa/></Flex></Col>
                        </Row>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}
                            items={[
                                {
                                    title: 'Główna',
                                },
                                {
                                    title: 'Panel',
                                },
                            ]}
                        />
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            {/*<ConfigProvider*/}
                            {/*    theme={{*/}
                            {/*        token: {*/}
                            {/*            // Seed Token*/}
                            {/*            colorPrimary: '#00b96b',*/}
                            {/*            borderRadius: 5,*/}

                            {/*            // Alias Token*/}
                            {/*            colorBgContainer: '#f6ffed',*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*<Flex style={{paddingBottom: 20}} align="flex-end" justify="right">*/}
                            {/*    <Button type="primary"><PlusOutlined /> Dodaj Subskrybenta</Button>*/}
                            {/*</Flex>*/}
                            {/*</ConfigProvider>*/}
                            <Table columns={columns} dataSource={data} />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        2SAdmin ©{new Date().getFullYear()} Created by LoveApp.pl
                    </Footer>
                </Layout>
            </Layout>
            <Drawer
                title="Edytuj dane klienta"
                width={720}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Anuluję</Button>
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#00b96b',
                                    borderRadius: 5,

                                    // Alias Token
                                    colorBgContainer: '#f6ffed',
                                },
                            }}
                        >
                            <Button onClick={onClose} type="primary">
                                Akceptuję
                            </Button>
                        </ConfigProvider>
                    </Space>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="E-mail:"
                                rules={[{ required: true, message: 'Pole E-mail jest wymagane!' }]}
                            >
                                <Input placeholder="Please enter user name" defaultValue="biuro@2strefa.pl"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="owner"
                                label="Wybierz Plan:"
                                rules={[{ required: true, message: 'Proszę wybrać Plan' }]}
                            >
                                <Select
                                    placeholder="Przypisz odpowiedni Plan"
                                    onChange={handleChange}
                                    options={[
                                        {
                                            label: <span>Generatywny</span>,
                                            title: 'Generatywny',
                                            options: [
                                                { label: <span>Plan Podstawowy - €29.00 / miesiąc </span>, value: 'podst_1' },
                                                { label: <span>Plan Podstawowy - €290.00 / rok</span>, value: 'podst_2' },
                                                { label: <span>Plan Pro - €99.00 / miesiąc </span>, value: 'pro_1' },
                                                { label: <span>Plan Pro - €990.00 / rok</span>, value: 'pro_2' },
                                                { label: <span>Plan Enterprise - Generatywny </span>, value: 'enter_p' },
                                            ],
                                        },
                                        {
                                            label: <span>Predykcyjny</span>,
                                            title: 'Predykcyjny',
                                            options: [
                                                { label: <span>Plan Starter - €50.00 / miesiąc </span>, value: 'starter_1' },
                                                { label: <span>Plan Starter - €500.00 / rok</span>, value: 'starter_2' },
                                                { label: <span>Plan Professionalo - €200.00 / miesiąc </span>, value: 'profess_1' },
                                                { label: <span>Plan Professional - €2000.00 / rok</span>, value: 'profess_2' },
                                                { label: <span>Plan Enterprise - Predykcyjny </span>, value: 'enterpr_2' },
                                            ],
                                        },
                                        {
                                            label: <span>SEO</span>,
                                            title: 'SEO',
                                            options: [
                                                { label: <span>Plan Srebrny - €49.00 / miesiąc </span>, value: 'srebrny_1' },
                                                { label: <span>Plan Srebrny - €490.00 / rok</span>, value: 'srebrny_2' },
                                                { label: <span>Plan Złoty - €199.00 / miesiąc </span>, value: 'zloty_1' },
                                                { label: <span>Plan Złoty - €1990.00 / rok</span>, value: 'zloty_2' },
                                                { label: <span>Plan Enterprise - SEO </span>, value: 'seoenter_2' },
                                            ],
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="status"
                                label="Status:"
                                rules={[{ required: true, message: 'Please select an owner' }]}
                            >
                                <Select placeholder="Wybierz status płatności">
                                    <Option value="xiao">Opłacony</Option>
                                    <Option value="mao">Nieopłacony</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Uwagi prywatne:"
                            >
                                <Input.TextArea rows={4} placeholder="Treść widoczna jest tylko do Twojej wiadomości" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title="Wiadomość do : biuro@2strefa.pl"
                width={720}
                onClose={onClose2}
                open={open2}
                extra={
                    <Space>
                        <Button onClick={onClose2}>Anuluję</Button>
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#efc806',
                                    borderRadius: 5,

                                    // Alias Token
                                    colorBgContainer: '#f6ffed',
                                },
                            }}
                        >
                            <Button onClick={onClose} type="primary">
                                Wyślij  <SendOutlined />
                            </Button>
                        </ConfigProvider>
                    </Space>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Col span={24}>
                        <Form.Item
                            name="rodzaj"
                            label="Rodzaj:"
                            rules={[{ required: true, message: 'Prosze podać rodzaj wiadomości!' }]}
                        >
                            <Select defaultValue={'nowa'}>
                                <Option value="nowa">Nowa Wiadomość</Option>
                                <Option value="przypisana">#WB343662 - z dnia 12.06.2024 - Brak działającego Modelu AI</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Temat:"
                                rules={[{ required: true, message: 'Temat jest wymagany!' }]}
                            >
                                <Input placeholder="Proszę wpisać temat wiadomości"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Wiadomość:"
                                rules={[{ required: true, message: 'Wiadomość jest wymagana!' }]}
                            >
                                <Input.TextArea rows={4} placeholder="Napisz wiadomość do klienta" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider/>
                </Form>
            </Drawer>
            <Drawer
                title="Alert do : biuro@2strefa.pl"
                width={720}
                onClose={onClose3}
                open={open3}
                extra={
                    <Space>
                        <Button onClick={onClose3}>Anuluję</Button>
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#efc806',
                                    borderRadius: 5,

                                    // Alias Token
                                    colorBgContainer: '#f6ffed',
                                },
                            }}
                        >
                            <Button onClick={onClose} type="primary">
                                Wyślij  <SendOutlined />
                            </Button>
                        </ConfigProvider>
                    </Space>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status:"
                            rules={[{ required: true, message: 'Proszę wybrać status Alertu!' }]}
                        >
                            <Select placeholder="Wybierz status Alertu">
                                <Option value="succes" style={{ backgroundColor: '#f6ffe6' }}><span style={{color:"green"}}><CheckCircleOutlined /> Sukces</span></Option>
                                <Option value="info" style={{ backgroundColor: '#e6f7ff' }}><span style={{color:"blue"}}><InfoCircleOutlined /> Info</span></Option>
                                <Option value="uwaga" style={{ backgroundColor: '#fffbe6' }}><span style={{color:"#f48b30"}}><WarningOutlined /> Uwaga</span></Option>
                                <Option value="ostrzezenie" style={{ backgroundColor: '#fff1f0' }}><span style={{color:"red"}}><StopOutlined /> Ostrzeżenie</span></Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="szalony"
                            label="Szablony:"
                        >
                            <Select placeholder="Wybierz szablon Alertu">
                                <Option value="wykonalismy" style={{ backgroundColor: '#f6ffe6' }}><span style={{color:"green"}}><CheckCircleOutlined /> Wykonaliśmy zadanie.</span></Option>
                                <Option value="potrzeujesz" style={{ backgroundColor: '#e6f7ff' }}><span style={{color:"blue"}}><InfoCircleOutlined /> Czego potrzebujesz?</span></Option>
                                <Option value="oplac" style={{ backgroundColor: '#fffbe6' }}><span style={{color:"#f48b30"}}><WarningOutlined /> Opłać suskrypcje.</span></Option>
                                <Option value="kontousuniete" style={{ backgroundColor: '#fff1f0' }}><span style={{color:"red"}}><StopOutlined /> Twoje konto zostanie usunięte!</span></Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Temat:"
                                rules={[{ required: true, message: 'Temat jest wymagany!' }]}
                            >
                                <Input placeholder="Proszę wpisać temat alertu"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Wiadomość:"
                                rules={[{ required: true, message: 'Wiadomość jest wymagana!' }]}
                            >
                                <Input.TextArea rows={4} placeholder="Napisz wiadomość alertu" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider/>
                    <ul className={'alrtoma'}>
                        <li>
                            <Alert
                                message="Udana Konfiguracja - 23.06.2024"
                                description="Udało nam się przeprowadzić udaną konfigurację na Pana koncie."
                                type="success"
                                showIcon
                            />
                        </li>
                        <li>
                            <Alert
                                message="Informational Notes - 11.04.2024"
                                description="Additional description and information about copywriting."
                                type="info"
                                showIcon
                            />
                        </li>
                        <li>
                            <Alert
                                message="Twoje dane zostały usunięte - 01.03.2024"
                                description="Additional description and information about copywriting."
                                type="error"
                                showIcon
                            />
                        </li>
                        <li>
                            <Alert
                                message="Prosze opłacić suskrypcję - 25.02.2024"
                                description="Twój Plan niedługo się zakończy. Proszę opłacić nową subskrypcję, aby zapobiec wstrzymaniu działania Modelu AI."
                                type="warning"
                                showIcon
                            />
                        </li>
                    </ul>
                </Form>
            </Drawer>
        </div>
    );
}

export default AdminPanel;