import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminPanel from './pages/AdminPanel';
import './App.css';
import KlienciPanel from "./pages/KlienciPanel";

const App: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/adminpanel" Component={AdminPanel} />
          <Route path="/klienci" Component={KlienciPanel} />
        </Routes>
      </Router>
  );
}

export default App;
