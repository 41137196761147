import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const LogotechAuth = (): void => {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/adminpanel');
        }
    }, [navigate]);
};

export default LogotechAuth;

