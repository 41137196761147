import React, { useEffect, useState } from 'react';
import { ConfigProvider, Card, Col, Row, Button, Form, type FormProps, Input} from 'antd';
import axios from 'axios';
import { Helmet, HelmetProvider} from "react-helmet-async";
import useAuth from "../components/useAuth";
import LogotechAuth from "../components/logotechAuth";
import { useNavigate } from "react-router-dom";

const centrowanie: React.CSSProperties = {
    textAlign: 'center',
};

type FieldType = {
    username?: string;
    password?: string;
};


const HomePage: React.FC = (message?: any) => {

    LogotechAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    useEffect(() => {
        document.body.style.backgroundColor = '#f0f2f5';
        return () => {
            document.body.style.backgroundColor = 'unset';
        };
    }, []);

    const onFinish = async (values: { username: string; password: string }) => {

        try {
            const apiUrl =  process.env.REACT_APP_API_URL;
            const response = await fetch(apiUrl+'/api/admin-token-auth/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({
                    email: username,
                    password: password,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                navigate('/adminpanel');
            } else {
                alert('Nie jesteś adminem. Wypierdalaj!!!');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                alert(error.response.data.detail);
            } else {
                // @ts-ignore
                alert(error.message);
            }
        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const title = (
        <span style={{ margin: 1 }}>
            <Col><img src="/images/logosyyy.png" alt="Logo" width={50} /></Col>
            <Col><h2 style={{ margin: "0" }}>Logowanie</h2></Col>
        </span>
        );

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#00b96b',
                    borderRadius: 2,

                    // Alias Token
                    colorBgContainer: '#ffffff',
                },
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>Logowanie | 2SAdmin</title>
                </Helmet>
            </HelmetProvider>
            <Row>
                <Col span={8}></Col>
                <Col span={8} style={centrowanie}>
                    <Card className={'bokser'} title={title} bordered={false} style={{ width: 400 }}>
                        <Form
                            name="basic"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                            style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item<FieldType>
                                label="Email"
                                name="username"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Wprowadzony tekst nie jest poprawnym adresem email!',
                                    },
                                    {
                                        required: true,
                                        message: 'Proszę wpisać adres email!',
                                    },
                                ]}
                            >
                                <Input value={username} onChange={e => setUsername(e.target.value)} required />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="Hasło"
                                name="password"
                                rules={[{ required: true, message: 'Proszę wpisać Hasło!' }]}
                            >
                                <Input.Password value={password} onChange={e => setPassword(e.target.value)} required />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    Logowanie
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}></Col>
            </Row>
        </ConfigProvider>
    );
}

export default HomePage;